@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

:root {
	--orange: #FF8934;
	--black: #181811 B;
	--blue: #1A73E8;
	--navy: #1B2153;
	--light: #F0F7FF;
	--gray: #EFF4FF;
	--yellow: #FED81D;
	--anim: all ease 0.25s;
}

body {
	font-family: 'DM Sans', sans-serif;
	color: var(--black);
	overflow-x: hidden;
	font-size: 18px;
}

a {
	text-decoration: none;
}

ul {
	padding: 0;
}

img {
	max-width: 100%;
}

h1,
h2,
h3 {
	font-weight: bold;
}

h1 {
	font-size: 46px !important;
}

.btn-light {
	background: var(--light);
	border-radius: 6px;
	min-width: 125px;
	color: #1A73E8;
	font-size: 18px !important;
	font-weight: 500;
	border: none;
}

.btn-light:hover {
	background: var(--blue) !important;
	color: #fff !important;
}

.bg-light {
	background: var(--light) !important;
}

#siteheader {
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
	z-index: 999;
	background: #fff;
	line-height: 1.5;
}

#siteheader.stickhead {
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
}

.navbar {
	padding: 0;
}

.navbar-brand img {
	height: 45px;
}

/*.stickhead .navbar-brand img{height:34px;}*/

.navbar-toggle {
	display: none;
	position: relative;
	width: auto;
	height: 40px;
	padding: 8px;
	vertical-align: bottom;
	margin-left: 30px;
	z-index: 999;
}

.navbar-toggle span {
	display: inline-block;
	text-transform: uppercase;
	padding-left: 50px;
}

.navbar-toggle:hover .hamburger-inner {
	background: #555;
	width: 30px;
}

.navbar-toggle:hover {
	color: #000;
}

.text-warning,
.text-yellow {
	color: var(--yellow) !important;
}

.navbar-brand {
	margin-right: 50px;
}

#menu {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	transition: var(--anim)
}

.menu_list {
	display: flex;
	width: 100%;
	margin: 0;
	list-style: none
}

.menu_list>li {
	padding: 0 15px;
}

.menu_list>li>a {
	display: block;
	padding: 18px 0;
	color: var(--black);
	position: relative;
	text-align: center;
	font-size: 16px;
}

.menu_list>li>a.active {
	text-shadow: 0 0 black;
}

.menu_list>li>a:after {
	content: '';
	display: block;
	height: 3px;
	width: 0;
	background: var(--blue);
	bottom: 0;
	position: absolute;
	right: 0;
	border: none;
	margin: 0;
	border-radius: 8px 8px 0px 0px;
}

.menu_list>li:hover>a:after {
	width: 100%;
	left: 0;
}

.menu_list>li>a:hover {
	color: #000 !important;
}

.whycx_drop div[class*="menu-"] {
	position: relative;
}

.whycx_drop .btn {
	position: absolute;
	bottom: 20px;
	right: 20px;
}

.menu_info {
	padding: 0;
	position: absolute;
	left: 0;
	top: 100px;
	opacity: 0;
	background: #fff;
	visibility: hidden;
	width: 100%;
	box-shadow: 0px 50px 70px rgba(0, 0, 0, 0.2);
	-webkit-transition: all ease 0.3s;
	-moz-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
	border: none;
	border-top: 1px solid #eee;
	margin: 0;
}

.menu_info .btn {
	height: 34px;
	font-size: 14px;
}

.whycx_drop.menu_info .row>div {
	padding-bottom: 65px;
}

.whycx_drop .menu-right h4 {
	margin-bottom: 20px;
}

.menuicon,
.loginbtn {
	display: none;
}

#hero {
	margin-top: 64px;
	position: relative;
	overflow: hidden;
	padding: 120px 0;
}

#hero h1 span {
	color: var(--blue)
}

#hero .h5 {
	margin: 25px 0 50px
}

#hero .h6 {
	margin-bottom: 20px
}

.heroimg {
	position: absolute;
	bottom: 0;
	width: 60%;
	z-index: -1;
	margin-left: -50px;
}

.homedemo {
	position: relative;
	max-width: 500px
}

.homedemo .form-control {
	height: 66px;
	font-size: 18px;
	background: #fff;
	padding: 0 174px 0 24px;
}

.homedemo .btn {
	width: 150px;
	font-size: 16px;
	height: 62px;
	font-weight: 500;
	position: absolute;
	top: 8px;
	right: 8px;
	height: 50px;
	border-radius: 6px;
	border: none;
	overflow: hidden;
}

.loader {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 2;
	background: #5c5c5c;
	transform: scale(0);
	transition: var(--anim);
	border-radius: 50%
}

.loader2 {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 2;
	/* background: #5c5c5c;
	transform: scale(0); */
	transition: var(--anim);
	border-radius: 50%
}
.loader2 img {
	height: 80%;
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
}
.loader2.show,
.success.show,
.error.show {
	transform: scale(1);
	border-radius: 0
}

.loader.show,
.success.show,
.error.show {
	transform: scale(1);
	border-radius: 0
}

.loader img {
	height: 80%;
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
}

.loader.show,
.success.show,
.error.show {
	transform: scale(1);
	border-radius: 0
}

.success,
.error {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 2;
	transform: scale(0);
	transition: var(--anim);
	border-radius: 50%
}

.success {
	background: #22ae73;
}

.success img {
	height: 100%;
}

.error {
	background: #fd6340;
}

.error img {
	height: 60%;
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
}

.msg-error {
	margin: 10px 10px 10px;
	font-size: 12px;
	text-align: center;
	color: #d91515;
}

.msg-success {
	margin: 16px 0 10px;
	font-size: 12px;
	text-align: center;
	position: absolute;
	width: 100%;
	left: 0;
}

.msg-success img {
	margin-top: -13px;
}

.msg-error img {
	filter: invert(12%) sepia(56%) saturate(7391%) hue-rotate(11deg) brightness(113%) contrast(90%);
	width: 13px;
	vertical-align: text-top;
	margin-right: 4px;
}

#problems {
	background: var(--light);
	padding: 70px 0;
	font-size: 15px;
}

#problems h2 {
	max-width: 730px;
	margin: auto;
	color: #111;
}

#problems .h5 {
	max-width: 800px;
	margin: 15px auto 30px
}

#problems #problemSlider {
	overflow: hidden;
	position: relative;
	padding-right: 60px;
}

#problems .lSSlideWrapper {
	padding-right: 60px;
}

#problems .problem .row {
	height: 100%;
}

#problems .problem .col-lg-auto {
	display: flex;
	align-self: center
}

#problemSlider .col-lg {
	align-self: center;
}

/*#problemSlider .owl-stage-outer{overflow:visible;}*/
/*#problemSlider .owl-carousel{transform: rotate3d(0, 0, 1, 90deg);}*/
/*#problemSlider .owl-item{transform: rotate3d(0, 0, 1, -90deg); padding-right:20px;}*/
/*#problemSlider .owl-item:first-child{transform: rotate3d(0, 0, 1, -90deg) translateX(-71px)}*/

#pronvs {
	position: absolute;
	right: 0;
	top: 50%;
	display: flex;
	flex-direction: column;
	transform: translateY(-50%);
	height: auto !important;
	width: auto !important
}

#pronvs .slick-dots {
	list-style: none;
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 0 !important;
}

#pronvs .slick-dots button {
	text-indent: -9999px;
	width: 3px;
	height: 25px;
	background: #D9D9D9;
	display: block;
	margin: auto;
	border: none;
	padding: 0;
}

#pronvs .slick-dots li {
	margin: 5px 0;
	padding: 0;
	width: 100%;
}

#pronvs .slick-dots li.slick-active button {
	background: var(--blue);
}

#pronvs .slick-arrow {
	background: var(--blue);
	display: block;
	border: none;
	color: #fff;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	font-size: 25px;
	line-height: 1;
	cursor: pointer;
}

/*
#problems .lSPrev:before,#problems .lSNext:before{font:normal 24px 'FontAwesome'; display: block;text-align: center;line-height:30px;}
#problems .lSPrev:before{content:'\f106';}
#problems .lSNext:before{content:'\f107';}
*/

.cta1 {
	background: url(./images/bg-pattern.svg) center left -350px no-repeat, url(./images/bg-pattern.svg) center right no-repeat;
	background-color: var(--navy);
	color: #fff;
	padding: 70px 0;
}

.cta1 h3 {
	font-size: 28px;
	font-weight: 500;
	max-width: 700px
}

.cta1 h4 {
	font-size: 24px;
	color: #FF8934;
	font-weight: 500;
}

#tshaped {
	padding: 70px 0;
}

#tshaped .row {
	flex-wrap: nowrap;
}

#tshaped .h5 {
	max-width: 500px;
}

#tshaped .img-container {
	background: url(./images/bg-pattern2.svg) no-repeat top center;
	position: relative;
}

#tshaped .cxCircles {
	margin: 100px auto 170px;
}

#tshaped .abs {
	position: absolute;
	font-size: 16px;
}

#tshaped .abs:before {
	content: '';
	display: block;
	position: absolute;
	width: 80px;
	height: 80px;
	background: url(./images/solArrow.svg) no-repeat;
}

#tshaped .abs h4 {
	display: none;
}

#tshaped .abs p {
	margin-bottom: 5px;
}

#tshaped .abs ul {
	margin-left: 25px;
}

#tshaped .csn {
	width: 200px;
	top: 410px;
	left: -140px;
}

#tshaped .csn:before {
	transform: rotate(90deg) scaleY(-1);
	top: -90px;
	right: 44px;
}

#tshaped .bsn {
	left: 49%;
	top: 410px;
}

#tshaped .bsn:before {
	transform: rotate(90deg);
	top: -90px;
	left: 0;
}

#tshaped .cnc {
	top: 0;
	left: 39%;
}

#tshaped .cnc:before {
	left: -93px;
	top: 28px;
}

#platform {
	background: #fafafa;
	padding: 50px 0;
	position: relative;
	min-height: 850px;
	overflow: hidden;
}

#platform h2 {
	margin-bottom: 10px;
}

#platform .nav-pills {
	flex-wrap: nowrap;
	margin-top: 30px;
}

#platform .nav-item {
	margin-right: 20px;
	flex: 1
}

#platform .nav-item:last-child {
	margin-right: 0;
}

#platform .nav-link {
	background: #fff;
	border: 1px solid var(--light);
	border-radius: 12px;
	color: var(--black);
	display: flex;
	align-items: center;
	text-align: left;
	font-size: 18px;
	font-weight: 500;
	padding: 15px;
	width: 100%;
}

#platform .nav-link h5 {
	font-size: 18px;
}

#platform .nav-link h5 span {
	font-size: 22px;
	font-weight: bold;
	display: block;
}

#platform .nav-link.active {
	background: var(--light);
	border-color: #1A73E8;
	color: var(--black);
	position: relative;
}

#platform .nav-link.active:after {
	content: '';
	display: block;
	background: url(./images/tabactive-arrow.svg);
	width: 23px;
	height: 10px;
	position: absolute;
	bottom: -10px;
	margin-left: -12.5px;
	left: 50%;
}

#platform .nav-link .imgwrapper {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	background: var(--light);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 15px;
	flex: 0 0 auto
}

#platform .nav-link img {}

#platform .nav-link.active .imgwrapper {
	background: #E0EDFF;
}

#platform .nav-link.active img {
	filter: invert(29%) sepia(97%) saturate(1278%) hue-rotate(193deg) brightness(105%) contrast(89%);
}

#platform .tab-content {
	margin-top: 50px;
}

.platform_mainimg {
	position: absolute;
	max-height: 500px;
	max-width: 69%;
}

#platform .tab-pane ul {
	list-style: none;
}

#platform .tab-pane ul li:before {
	content: '';
	display: inline-block;
	background: url(./images/arrow-green.svg);
	width: 16px;
	height: 13px;
	position: absolute;
	top: 7px;
	left: 0;
}

#platform .tab-pane ul li {
	padding-left: 25px;
	position: relative;
	margin-bottom: 12px;
}

#platform h4 {
	color: var(--navy);
	margin-bottom: 30px;
	font-weight: bold;
}

#tree {
	text-align: center;
	font-weight: 500;
}

#tree ul {
	display: flex;
	justify-content: center;
	list-style: none;
	padding: 0;
	margin: 0;
}

#tree span {
	display: block;
	background: #FFFFFF;
	border: 2px solid #E7E7E7;
	box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	padding: 10px 14px;
	min-width: 150px
}

.r1 {
	align-items: flex-start
}

.r1 img {
	position: relative;
	z-index: 2;
}

#tree .r2 {
	margin-top: -7px;
}

#tree .r2 em {
	display: none;
}

.r2 li {
	width: 301px;
}

.r2 li:nth-child(2) {
	margin: 0 20px;
}

.r2 img {
	display: block;
	margin: 5px auto 0
}

#tree .r2 span {
	padding: 25px 10px;
	color: #1C2150;
}

#tree .r2 span strong {
	font-weight: 500;
	display: block;
}

#tree .r2 ul {
	display: block;
}

#tree .r2 ul:before {
	content: '';
	display: block;
	background: url(./images/r3.svg) no-repeat;
	width: 12px;
	height: 69px;
	margin: 0 auto -7px;
	position: relative;
	z-index: 2;
}

#tree .r2 ul span {
	padding: 15px 20px;
	height: 160px;
}

.r2-arrow {
	position: relative;
	z-index: 2;
}

.r2-arrow .r2mid {
	margin: 0 -13px;
	height: 93.34%;
}

.r2-arrow .rfirst,
.r2-arrow .rlast {
	margin-left: -1px;
	width: 35%;
}

#whycx {
	padding: 60px 0 70px;
}

#whycx h2 {
	margin-bottom: 50px;
}

h2 {
	color: var(--navy);
	font-size: 44px;
	margin-bottom: 30px;
}

h2 span {
	color: var(--blue)
}

.h5 {
	color: #5D6180;
	font-size: 18px;
	font-weight: normal;
	line-height: 1.6;
}

.treelogo {
	margin-top: -7px;
}

#cxstats {
	padding: 70px 0 50px;
	background: #FAFAFA;
}

.stats div[class*="col-"] {
	display: flex;
	margin-bottom: 20px;
}

.stats .img-container {
	background: var(--light);
	border-radius: 16px;
	width: 62px;
	height: 62px;
	text-align: center;
	line-height: 62px;
	flex: 0 0 auto;
	margin-right: 15px;
}

.stats .h1 {
	font-size: 52px;
	color: var(--blue);
	font-weight: bold;
	margin: 0;
}

.stats .h1 span {
	font-size: 75%;
}

.stats p {
	color: #585858;
	line-height: 1.3;
	max-width: 220px
}

#testimonials {
	background: url(./images/testimonials-bg.jpg) no-repeat;
	background-size: cover;
	padding: 50px 0 70px;
}

#testimonials h2 {
	margin-bottom: 40px;
}

#testimonials .slick-track {
	display: flex
}

#testimonials .slick-slide {
	background: #fff;
	border-radius: 5px;
	padding: 30px;
	height: auto;
	margin: 0 10px;
}

#testimonials .d-flex {
	align-items: flex-start;
	margin-bottom: 20px;
}

#testimonials img {
	width: 43px;
	flex: 0 0 auto;
	border-radius: 50%;
	margin-right: 16px;
}

#testimonials .cmtname {
	font-size: 16px;
	font-weight: 500;
}

#testimonials .cmtdesig {
	font-size: 14px;
	color: #5D6180
}

#testimonials .slick-dots {
	text-align: center;
	margin-top: 30px;
}

#testimonials .slick-dots li button {
	width: 30px;
	height: 4px;
	margin: 5px 5px;
	background: #aaa;
	border-radius: 5px;
	border: none;
	display: block;
	text-indent: -9999px;
	-webkit-backface-visibility: visible;
	transition: opacity .2s ease;
}

#testimonials .slick-dots li {
	display: inline-block;
	zoom: 1;
}

#testimonials .slick-dots li.slick-active button {
	background: var(--blue);
}




/*#sitefooter{padding-top:70px;}*/
#fcta {
	background: var(--navy) url(./images/bg-pattern.svg) no-repeat top right;
	background-size: 500px auto;
	border-radius: 20px;
	padding: 40px 60px;
	margin-top: 70px;
}

#fcta h2 {
	color: #fff;
	text-transform: capitalize;
	font-weight: 600;
}

#fcta h2 span {
	color: #FF8934;
}

#fcta .homedemo {
	max-width: 600px;
	margin: 40px 0 20px;
}

#mainfooter {
	padding: 50px 0;
	background: url(./images/footer-pattern.svg) no-repeat left center;
}

#mainfooter a {
	color: #18181B;
	font-size: 16px;
}

#mainfooter h5 {
	font-size: 16px;
	font-weight: bold;
	color: var(--navy);
	margin-top: 30px;
}

#mainfooter ul {
	list-style: none;
	padding: 0;
}

#mainfooter li {
	margin-bottom: 5px;
}

.footcxinfo {
	font-size: 16px;
	color: #5D6180;
	margin-top: 16px;
}

.copyright {
	color: #5D6180;
	font-size: 14px;
}

.credit {
	margin-bottom: 20px;
}

.social-footer a {
	color: #5D6180;
	display: inline-block;
	font-size: 22px;
	margin: 0 5px;
}

.social-footer {
	padding-right: 35px;
}

#nav-toggle {
	background: none;
	border: none;
	padding: 0;
	position: fixed;
	top: 5px;
	right: 5px;
	z-index: 10001;
	overflow: hidden;
}

.menu_overlay {
	background: #000;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	opacity: .7;
	position: fixed;
	z-index: 995;
	display: none;
}

/*Hamburger Icon*/
.ham {
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	transition: transform 400ms;
	transform: scale(1.5, 1);
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.hamRotate.active {
	transform: rotate(45deg);
}

.hamRotate180.active {
	transform: rotate(180deg);
}

.hamRotate.active .line {
	stroke: #fff
}

.line {
	fill: none;
	transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
	stroke: #666;
	stroke-width: 4;
	stroke-linecap: round;
}

.ham1 .top {
	stroke-dasharray: 40 139;
}

.ham1 .bottom {
	stroke-dasharray: 40 180;
}

.ham1.active .top {
	stroke-dashoffset: -98px;
}

.ham1.active .bottom {
	stroke-dashoffset: -138px;
}

.ham2 .top {
	stroke-dasharray: 40 121;
}

.ham2 .bottom {
	stroke-dasharray: 40 121;
}

.ham2.active .top {
	stroke-dashoffset: -102px;
}

.ham2.active .bottom {
	stroke-dashoffset: -102px;
}

.ham3 .top {
	stroke-dasharray: 40 130;
}

.ham3 .middle {
	stroke-dasharray: 40 140;
}

.ham3 .bottom {
	stroke-dasharray: 40 205;
}

.ham3.active .top {
	stroke-dasharray: 75 130;
	stroke-dashoffset: -63px;
}

.ham3.active .middle {
	stroke-dashoffset: -102px;
}

.ham3.active .bottom {
	stroke-dasharray: 110 205;
	stroke-dashoffset: -86px;
}

.ham4 .top {
	stroke-dasharray: 40 121;
}

.ham4 .bottom {
	stroke-dasharray: 40 121;
}

.ham4.active .top {
	stroke-dashoffset: -68px;
}

.ham4.active .bottom {
	stroke-dashoffset: -68px;
}

.ham5 .top {
	stroke-dasharray: 40 82;
}

.ham5 .bottom {
	stroke-dasharray: 40 82;
}

.ham5.active .top {
	stroke-dasharray: 14 82;
	stroke-dashoffset: -72px;
}

.ham5.active .bottom {
	stroke-dasharray: 14 82;
	stroke-dashoffset: -72px;
}

.ham6 .top {
	stroke-dasharray: 40 172;
}

.ham6 .middle {
	stroke-dasharray: 40 111;
}

.ham6 .bottom {
	stroke-dasharray: 40 172;
}

.ham6.active .top {
	stroke-dashoffset: -132px;
}

.ham6.active .middle {
	stroke-dashoffset: -71px;
}

.ham6.active .bottom {
	stroke-dashoffset: -132px;
}

.ham7 .top {
	stroke-dasharray: 40 82;
}

.ham7 .middle {
	stroke-dasharray: 40 111;
}

.ham7 .bottom {
	stroke-dasharray: 40 161;
}

.ham7.active .top {
	stroke-dasharray: 17 82;
	stroke-dashoffset: -62px;
}

.ham7.active .middle {
	stroke-dashoffset: 23px;
}

.ham7.active .bottom {
	stroke-dashoffset: -83px;
}

.ham8 .top {
	stroke-dasharray: 40 160;
}

.ham8 .middle {
	stroke-dasharray: 30 142;
	transform-origin: 50%;
	transition: transform 400ms;
}

.ham8 .bottom {
	stroke-dasharray: 40 85;
	transform-origin: 50%;
	transition: transform 400ms, stroke-dashoffset 400ms;
}

.ham8.active .top {
	stroke-dashoffset: -64px;
}

.ham8.active .middle {
	stroke-dasharray: 40 142;
	transform: rotate(90deg);
}

.ham8.active .bottom {
	stroke-dashoffset: -64px;
}

#pageheader {
	margin-top: 60px;
	background: var(--blue);
	color: #fff;
	padding: 50px 0;
	position: relative;
}

#pageheader:before {
	content: '';
	display: block;
	background: url(./images/pageheader.jpg) no-repeat center bottom;
	background-size: cover;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: absolute;
	z-index: 0;
	opacity: 0.7;
}

#pageheader h1,
#pageheader .pageTitle {
	margin: 0;
	font-size: 46px !important;
	color: #fff;
	font-weight: bold;
}

#pageheader+#content {
	margin-top: 0;
}

#pageheader .container {
	position: relative;
}

#pageheader .btn-outline-light {
	border-color: #fff;
	color: #fff;
	border-radius: 50px;
	padding: 3px 20px;
}

#pageheader .btn-outline-light i {
	margin-right: 5px;
}

#pageheader .btn-outline-light:hover {
	background: #fff;
	color: #0e3061;
}

#content {
	border-bottom: 1px solid #ddd;
	margin-top: 60px;
	min-height: 300px;
	padding: 40px 0;
	font-size: 14px;
	color: #444;
}

#content h2 {
	font-size: 34px;
	color: var(--blue);
}

#content h3 {
	font-size: 27px;
	color: var(--navy)
}

#content h4 {
	font-size: 20px;
	margin-top: 30px;
	font-weight: bold;
}

#guideText img {
	border: 1px solid #ddd;
	border-radius: 7px;
	margin-bottom: 20px;
	vertical-align: bottom;
}

#guideText ul {
	padding-left: 17px;
}

#guideText h2 {
	margin-bottom: 15px;
}

#guideText h3+h4 {
	margin-top: 15px;
}



#guides {
	position: sticky;
	top: 80px;
}

#guides .accordion-button {
	padding: 10px 15px;
}

#guides .accordion-button:not(.collapsed) {
	color: #000;
	background-color: #f7faff;
}

#guides .accordion-button:focus {
	box-shadow: none
}

#guides .accordion-body {
	padding: 10px 10px;
}

#guides ul {
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 14px;
}

#guides ul ul {
	padding-left: 10px;
}

#guides ul ul a {
	position: relative;
	padding: 3px 10px 3px 13px;
}

#guides ul ul a:before {
	content: '\f105';
	display: block;
	font: normal 13px 'FontAwesome';
	position: absolute;
	left: 4px;
	top: 6px;
}

#guides li a {
	color: #666;
	padding: 3px 10px;
	display: block;
	border: 1px solid transparent;
	border-radius: 3px;
	transition: ease all 0.15s;
}

#guides li a:not(.active):hover {
	color: #000;
	border-color: #f7faff;
	background-color: #f7faff
}

#guides li a.active {
	color: var(--blue);
	text-shadow: 0 0 var(--blue)
}

/*#cta_form .btn-close{background-size:0.75em;}*/
#cta_form .modal-title {
	font-weight: 500;
	font-size: 22px !important;
	line-height: 1.1;
}

#cta_form .modal-header {
	border: none;
	padding: 20px 25px 10px;
}

#cta_form .modal-body {
	padding: 10px 25px 30px;
}

#cta_form .form-control,
#cta_form .form-select {
	border-radius: 3px;
	border-color: rgba(0, 0, 0, 0.2);
	height: 44px;
}

#cta_form textarea.form-control {
	height: 70px;
}

#cta_form .btn {
	margin-top: 20px;
	width: 100%;
	border-radius: 3px;
}

#cta_form .form-select {
	color: #444
}

#cta_form .form-control,
#cta_form .form-select {
	border-radius: 5px
}

.enqbtn {
	position: fixed;
	/*right:26px;top:70%; transform:rotate(90deg); transform-origin:bottom right; border-radius:0 0 7px 7px;padding:3px 10px; */
	background: #ff8025;
	color: #fff;
	border: none;
	bottom: 8px;
	right: 8px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	z-index: 3;
	font-size: 18px;
	padding: 3px 5px 3px 3px;
}

.contact-page {
	padding: 70px 0 70px !important;
}

.contact-page h1 {
	font-size: 60px;
	color: #111;
}

.cinfo {
	color: #5D6180;
	font-size: 18px;
}

.cinfo a {
	font-weight: 500;
	color: #6000C4;
}

.cform-wrapper {
	padding-left: 100px;
	padding-right: 25px;
}

.cform:after {
	content: '';
	display: block;
	height: 4px;
	background: #5D6180;
	position: absolute;
	bottom: -4px;
	right: -25px;
	left: -25px;
}

.cform:before {
	content: '';
	display: none;
	height: 4px;
	width: 34px;
	background: #5D6180;
	position: absolute;
	bottom: -4px;
	left: -84px;
}

.cform {
	background: rgba(255, 255, 255, 0.5);
	border: 4px solid #5D6180;
	border-radius: 12px 12px 0px 0px;
	padding: 40px;
	max-width: 650px;
	margin: auto;
	position: relative;
}

textarea {
	height: auto !important
}

.cform .form-control,
.cform .form-select {
	background-color: #F0F7FF;
	border-radius: 4px;
	border-color: transparent;
	font-weight: 500;
	height: 48px;
	font-size: 14px;
}

.cform label {
	font-size: 16px;
	font-weight: bold;
	color: #18181B;
}

.cform label span {
	display: inline;
	color: #FF6846;
}

.cform .btn {
	width: 100%;
	height: 58px;
	border-radius: 4px;
}

.cform .form-control:focus,
.cform .form-select:focus {
	box-shadow: none;
	border-color: #c4d9f2
}

.cxtab-wrap {
	background: #fff;
	position: sticky;
	top: 55px;
	padding-top: 10px;
}

#cxCirclessl {
	margin-top: 20px;
}

#cxCirclessl>div {
	padding: 20px 0;
}

#cxCirclessl ul {
	padding-left: 20px;
}

#collapse_tabs .card {
	margin-bottom: 20px;
	border-color: #bed4f4;
}

#collapse_tabs .accordion-button {
	padding: 10px 15px;
	cursor: pointer;
}

#collapse_tabs .accordion-button:not(.collapsed) {
	background-color: #fbfcff;
}

#collapse_tabs p:last-child {
	margin-bottom: 0;
}


/*404 Page*/

.error-page {
	background: #052848 url(./images/404-error.jpg) no-repeat center center fixed;
	background-size: cover;
}

.error-msg-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 50px;
	padding-bottom: 50px;
}

.error-msg-wrapper p {
	font-size: 17px;
	text-align: center;
	color: rgba(255, 255, 255, 0.8);
	margin: 0 0 15px;
}

.error-page#content {
	background-size: cover;
	color: #fff;
	letter-spacing: 1px;
}

.error-page .btn {
	background: #fff;
	color: var(--blue);
	font-weight: 700;
	font-size: 16px;
	border-radius: 50px;
	padding: 2px 20px 2px 2px;
	display: flex;
	align-items: center;
	overflow: hidden;
	border: 1px solid var(--blue);
	transition: var(--anim);
	text-align: center;
}

.error-page .btn i {
	background: var(--blue);
	color: #fff;
	border-radius: 50%;
	width: 34px;
	height: 34px;
	font-size: 26px;
	margin-right: 12px;
	transition: var(--anim);
	padding-right: 5px;
	box-sizing: border-box
}

.error-page .btn i:before {
	margin: 0;
	line-height: 34px
}

.error-page .btn:hover {
	background: var(--blue);
	color: #fff;
}

.error-page .btn:hover i {
	background: #fff;
	color: var(--blue);
	-webkit-animation: fadeOutnIn 0.5s linear;
	animation: fadeOutnIn 0.5s linear;
}

.error404 h1 {
	font-size: 60px;
	margin: 5px 0;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.error-page h4 {
	font-size: 25px;
	color: rgba(255, 255, 255, 0.5);
	margin-bottom: 0;
}

.error404+#sitefooter {
	position: fixed;
	bottom: 10px;
	right: 0;
	left: 0;
	background: none;
}

.error404+#sitefooter .copyright {
	color: #fff;
}

.error404+#sitefooter .social-footer a {
	color: #fff;
}

.error404+#sitefooter .credit {
	margin-bottom: 0;
}

@-webkit-keyframes fadeOutnIn {

	0%,
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}

	45% {
		-webkit-transform: translateX(-30%);
		transform: translateX(-30%);
		opacity: 0;
	}

	55% {
		-webkit-transform: translateX(20%);
		transform: translateX(20%);
		opacity: 0;
	}
}

@keyframes fadeOutnIn {

	0%,
	100% {
		transform: translateX(0);
		opacity: 1;
	}

	45% {
		transform: translateX(-30%);
		opacity: 0;
	}

	55% {
		transform: translateX(30%);
		opacity: 0;
	}
}

.agreebtn {
	color: #777;
	margin-bottom: 5px;
}

.cform .form-check-label {
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 5px;
}

.cform .form-check-label a {
	display: inline-block;
}

#bloglist {
	list-style: none;
	padding: 0;
	margin: 0;
}

#bloglist li {
	padding: 10px 20px;
}

#bloglist img {
	border-radius: 5px;
	margin-bottom: 15px;
}

.bl_wrapper {
	padding-bottom: 40px;
}

.blogDate {
	border: 1px solid #D9D9D9;
	border-radius: 5px;
	color: #888;
	font-size: 14px;
	letter-spacing: 1.5px;
	padding: 6px 6px 6px 7px;
	line-height: 0.9;
}

.blogDate span {
	display: block;
	color: #222;
	font-size: 25px;
	font-weight: bold;
	line-height: 1;
	letter-spacing: 0;
	margin-bottom: 3px;
}

#bloglist .col {
	position: relative;
}

#bloglist .blogTitle {
	font-size: 18px !important;
	font-weight: 500;
	color: #444;
	margin-bottom: .5rem;
}

#bloglist p {
	color: #444;
}

#bloglist .bl_wrapper:hover .btn {
	opacity: 1;
	bottom: -35px;
}

#bloglist .btn {
	padding: 5px 20px;
	border-radius: 3px;
	opacity: 0;
	position: absolute;
	bottom: -45px;
	transition: var(--anim)
}

#blogDetail {
	font-size: 16px;
}

#blogDetail .blogTitle {
	font-size: 30px;
	font-weight: bold;
	color: #333;
	margin: 0;
}

#blogDetail img {
	margin: 20px 0;
}

#blogDetail h2 {
	font-size: 20px !important;
	color: #333;
	margin: 30px 0 10px;
}

#blogDetail h3 {
	font-size: 18px !important;
	color: #333;
	margin: 30px 0 10px;
}

#aside_blist {
	background: #FDFDFD;
	border-color: #f2f2f2
}

#aside_blist .card-header {
	background: #F7FAFF;
	font-size: 15px;
	color: #444;
	text-transform: uppercase;
	font-weight: bold;
	text-align: center;
}

#aside_blist .card-body {
	padding: 0;
}

#aside_blist ul {
	list-style: none;
	margin: 0;
}

#aside_blist li {
	border-bottom: 1px solid #f2f2f2;
	padding: 10px;
}

#aside_blist li:last-child {
	border-bottom: none;
}

#aside_blist h6 {
	font-weight: normal;
	font-size: 15px;
}

#aside_blist small {
	font-size: 13px;
	color: #888;
}

#aside_blist a {
	color: #444;
}

#aside_blist a:hover {
	color: #000;
}

/*.blogCol{padding-right:40px;}*/

@media all and (max-width:1399px) {
	#tshaped .cnc {
		left: 45%;
	}

	#tshaped .bsn {
		left: 56%;
	}

	h2 {
		line-height: 1.1;
		font-size: 40px;
	}

	#hero {
		padding: 90px 0;
	}

	.contact-page h1 {
		font-size: 50px;
	}

	.cform label {
		font-size: 14px;
	}

	#problems h2 {
		max-width: 660px;
	}
}

@media all and (max-width:1300px) {
	.cform-wrapper {
		padding-left: 30px;
	}
}

@media all and (max-width:1199px) {
	h2 {
		font-size: 34px;
	}

	#tshaped .cnc {
		left: 53%;
		font-size: 15px;
	}

	#tshaped .bsn {
		left: 58%;
		width: auto;
	}

	#tshaped .bsn:before {
		left: 43px;
	}

	#tshaped .h5 {
		max-width: 280px
	}

	#platform {
		min-height: 800px
	}

	.heroimg {
		bottom: auto;
		width: 70%;
	}

	#problems h2 {
		max-width: 540px;
	}

	#bloglist .btn {
		opacity: 1;
		bottom: -35px;
	}
}

@media all and (max-width:991px) {
	body {
		font-size: 14px;
	}

	h2 {
		font-size: 26px;
		line-height: 1.3;
	}

	#tshaped .row {
		flex-wrap: wrap
	}

	#tshaped .img-container {
		text-align: center;
	}

	#tshaped .abs {
		text-align: left;
	}

	#platform {
		min-height: 0;
		overflow: hidden;
	}

	.container,
	.container-md,
	.container-sm {
		max-width: 100%
	}

	.heroimg {
		position: relative;
		right: auto;
		bottom: auto;
		width: 100%;
		margin: 0;
	}

	#hero {
		display: block !important;
		text-align: center;
		padding: 50px 0 0;
	}

	.homedemo {
		margin-left: auto;
		margin-right: auto
	}

	#hero h1 {
		max-width: 600px;
		margin: auto;
		font-size: 32px;
	}

	#hero .h5 {
		font-size: 16px;
	}

	.cta1 {
		text-align: center;
		padding: 50px 0;
	}

	.cta1 h4 {
		margin: 30px;
	}

	#tshaped .h5 {
		max-width: 100%
	}

	#tshaped .img-container {
		margin-top: 30px;
	}

	#tshaped .cnc {
		width: 300px;
		left: 61%;
	}

	#tshaped .bsn {
		left: 66%;
	}

	#tshaped .csn {
		left: 6%;
	}

	.r2 li {
		width: 33.33%;
	}

	.r2 ul li {
		width: 100%;
	}

	.cta1 h3 {
		max-width: 100%
	}

	#problems,
	.cta1,
	#tshaped,
	#platform,
	#whycx h2,
	#cxstats,
	#testimonials,
	#sitefooter {
		padding-left: 30px;
		padding-right: 30px;
	}

	.h5,
	.footcxinfo {
		font-size: 14px;
	}

	.footcxinfo br {
		display: none;
	}

	#tshaped .abs {
		font-size: 14px;
	}

	#platform .nav-link {
		display: block;
		text-align: center;
	}

	#platform .nav-link .imgwrapper {
		margin: 10px auto
	}

	#mainfooter a {
		font-size: 14px;
	}
}

@media all and (min-width:1200px) {
	.r2 li {
		width: 340px;
	}

	#tree .r2 ul span {
		height: auto
	}
}

@media all and (min-width:1440px) {
	.heroimg {
		width: auto;
	}
}

@media screen and (max-width:991px) {
	#guides {
		position: fixed;
		z-index: 1000;
		top: 0;
		bottom: 0;
		left: -290px;
		width: 280px;
		background: #fff;
		padding: 10px;
		opacity: 0;
		transition: ease all 0.2s;
	}

	#guides.show {
		left: 0;
		opacity: 1;
	}

	#guides .btn-close {
		position: absolute;
		right: 8px;
		top: 7px;
		z-index: 3;
		background-color: #fff;
		background-size: 11px;
		opacity: 1;
		padding: 7px;
		border-radius: 50%
	}

	.guidebtn {
		position: fixed;
		background: #ff8025;
		color: #fff;
		border: none;
		bottom: 8px;
		right: 8px;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		z-index: 3;
		font-size: 18px;
		padding: 3px 5px 3px 3px;
	}

	#pageheader {
		padding: 30px 0;
	}

	#pageheader h1,
	#pageheader .pageTitle {
		font-size: 35px;
	}

	.cform:before {
		display: none;
	}

	.cform:after {
		left: -25px;
	}

	.ctext {
		padding: 0 50px;
	}

	.cform-wrapper {
		padding: 0 50px;
		margin-top: 30px;
	}

	.cform {
		max-width: 100%;
	}

	.contact-page {
		padding-top: 40px !important;
	}

	.owl-carousel .owl-item img {
		width: 150px;
	}

	#problems .problem {
		padding-bottom: 10px;
	}

	#problems .problem .row {
		height: auto
	}

	#problems .problem img {
		margin: 0 auto 10px;
	}
}

@media all and (max-width:767px) {
	#tshaped .abs {
		position: relative;
		left: 0;
		right: auto;
		bottom: auto;
		top: auto;
		margin-bottom: 30px;
		width: auto
	}

	#tshaped .abs h4 {
		margin-bottom: 5px;
	}

	#tshaped .abs:before {
		display: none;
	}

	#tshaped .bsn {
		margin-bottom: 0;
	}

	#menu {
		position: fixed;
		z-index: 9999;
		top: 0;
		bottom: 0;
		left: -290px;
		width: 280px;
		align-items: flex-start;
		overflow: hidden;
		/*overflow-y:auto; */
		background: #fff;
		padding-bottom: 60px;
	}

	#menu.showmenu {
		left: 0;
	}

	.menu_list {
		height: 100%;
	}

	.menuicon {
		display: block;
	}

	.showmenu .loginbtn {
		display: block;
	}

	.loginbtn {
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 10px;
		width: 100%;
	}

	.loginbtn a {
		width: 100%;
		margin: 0 !important;
	}

	.menu_list>li {
		padding: 0;
	}

	.menu_list>li>a {
		text-align: left;
		padding: 10px 15px;
	}

	.menu_list>li>a:after {
		height: 1px;
		opacity: 0.2
	}

	#problems,
	.cta1,
	#tshaped,
	#platform,
	#whycx h2,
	#cxstats,
	#testimonials,
	#sitefooter {
		padding-left: 10px;
		padding-right: 10px;
	}

	#platform .nav-pills {
		margin-left: -15px;
		margin-right: -15px;
	}

	#platform .nav-link h5 {
		font-size: 12px;
	}

	#platform .nav-link h5 span {
		font-size: 14px;
	}

	#platform .nav-item {
		width: 33.33%;
		flex: 0 0 auto;
		margin: 0;
		padding: 0 4px;
	}

	#platform .nav-link {
		padding: 5px;
	}

	#platform .nav-link img {
		height: 35px;
	}

	#platform .nav-link .imgwrapper {
		width: 58px;
		height: 58px;
	}

	.platform_mainimg {
		position: relative;
		max-width: 100%
	}

	#cxstats {
		padding: 50px 0;
	}

	h2 {
		margin-bottom: 20px;
	}

	.stats {
		margin-top: 30px;
	}

	.stats .h1 {
		font-size: 42px;
	}

	.stats p {
		font-size: 18px !important;
		max-width: 100%;
	}

	.homedemo .form-control {
		font-size: 14px;
		height: 50px;
		padding: 0 110px 0 15px;
	}

	.homedemo .btn {
		width: 115px;
		height: 42px;
		right: 4px;
		top: 4px;
		font-size: 14px;
		padding: 5px;
	}

	#fcta {
		padding: 30px 20px 20px;
	}

	#fcta h2 {
		font-size: 22px;
	}

	#sitefooter {
		background: url(./images/footer-pattern.svg) no-repeat left bottom 50px;
		padding: 20px 0 40px;
	}

	#mainfooter {
		background: url(./images/footer-pattern-right.svg) no-repeat right top 150px;
		padding-bottom: 20px;
	}

	.credit {
		text-align: center;
	}

	.social-footer {
		text-align: center !important;
		margin-bottom: 5px;
	}

	.social-footer a {
		width: 44px;
		height: 44px;
		font-size: 25px;
		line-height: 1.8;
	}

	#tshaped .abs h4 {
		display: block;
	}

	#tshaped .cxCircles {
		margin: 0 0 30px;
	}

	#tshaped .img-container {
		background: none;
		margin-top: 0;
		display: none;
	}

	#tshaped .abs h4 {
		font-size: 16px;
	}

	#tshaped {
		padding: 50px 0 30px;
	}

	#problems {
		padding: 50px 0;
	}

	#hero {
		padding-top: 20px;
	}

	#hero .h5 {
		font-size: 15px;
	}

	#hero .h5 br {
		display: none;
	}

	#hero .h6 {
		font-size: 14px;
	}

	#hero .homedemo {
		margin-bottom: 20px;
	}

	.cta1 h3 {
		font-size: 20px;
		text-align: left;
	}

	.cta1 h4 {
		font-size: 20px;
		text-align: left;
		margin-left: 0;
	}

	.r2 li {
		width: 100%;
		margin: 0 !important;
		font-size: 18px !important;
		font-weight: 500;
	}

	#tree .r2 em {
		display: inline-block;
		font-style: normal;
		background: var(--light);
		border-radius: 6px;
		padding: 10px 20px;
		margin-top: 10px;
		font-size: 14px;
		font-weight: normal;
	}

	.treelogo {
		background: var(--blue);
		border-radius: 6px;
		text-align: center;
	}

	.treelogo img {
		height: 60px;
	}

	#tree {
		width: 300px;
		margin: auto
	}

	#tree .r1 span {
		min-width: 100px;
		font-size: 15px;
	}

	#tree .r2 ul {
		display: none;
	}

	#tree .r2 {
		display: block;
	}

	#tree .r2 {
		padding-left: 60px;
	}

	#tree .r2 li {
		padding: 15px 0 !important;
	}

	#tree ul li {
		text-align: left;
		position: relative;
	}

	#tree ul li span {
		text-align: center;
	}

	#tree .r2>li:before,
	#tree .r2>li:after {
		content: '';
		display: block;
		position: absolute;
		left: -59px;
		height: 50%;
		width: 3px;
	}

	#tree .r2>li:before {
		top: 0;
	}

	#tree .r2>li:after {
		top: 50%;
	}

	#tree .r2>li:last-child:after {
		display: none;
	}

	#tree .r2 span:before {
		content: '';
		display: block;
		height: 3px;
		width: 60px;
		position: absolute;
		left: -24%;
		top: 50%;
		background: #fa772e;
		background: -moz-linear-gradient(left, #fa772e 0%, #2e42f1 100%);
		background: -webkit-linear-gradient(left, #fa772e 0%, #2e42f1 100%);
		background: linear-gradient(to right, #fa772e 0%, #2e42f1 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fa772e', endColorstr='#2e42f1', GradientType=1);
	}

	#tree .r2>li:first-child:before {
		background: #4f4ad2;
		background: -moz-linear-gradient(top, #4f4ad2 0%, #6a51b8 100%);
		background: -webkit-linear-gradient(top, #4f4ad2 0%, #6a51b8 100%);
		background: linear-gradient(to bottom, #4f4ad2 0%, #6a51b8 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4f4ad2', endColorstr='#6a51b8', GradientType=0);
	}

	#tree .r2>li:first-child:after {
		background: #6a51b8;
		background: -moz-linear-gradient(top, #6a51b8 0%, #8a5999 100%);
		background: -webkit-linear-gradient(top, #6a51b8 0%, #8a5999 100%);
		background: linear-gradient(to bottom, #6a51b8 0%, #8a5999 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6a51b8', endColorstr='#8a5999', GradientType=0);
	}

	#tree .r2>li:nth-child(2):before {
		background: #8a5999;
		background: -moz-linear-gradient(top, #8a5999 0%, #b06375 100%);
		background: -webkit-linear-gradient(top, #8a5999 0%, #b06375 100%);
		background: linear-gradient(to bottom, #8a5999 0%, #b06375 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8a5999', endColorstr='#b06375', GradientType=0);
	}

	#tree .r2>li:nth-child(2):after {
		background: #b06375;
		background: -moz-linear-gradient(top, #b06375 0%, #d46d52 100%);
		background: -webkit-linear-gradient(top, #b06375 0%, #d46d52 100%);
		background: linear-gradient(to bottom, #b06375 0%, #d46d52 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b06375', endColorstr='#d46d52', GradientType=0);
	}

	#tree .r2>li:last-child:before {
		height: calc(50% + 3px);
		background: #d46d52;
		background: -moz-linear-gradient(top, #d46d52 0%, #fa772e 100%);
		background: -webkit-linear-gradient(top, #d46d52 0%, #fa772e 100%);
		background: linear-gradient(to bottom, #d46d52 0%, #fa772e 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d46d52', endColorstr='#fa772e', GradientType=0);
	}

	.tlogoPath {
		margin-bottom: 5px;
	}

	#whycx,
	#testimonials {
		padding: 50px 0 40px;
	}

	.cxCircles_tabs {
		width: 290px;
		height: 275px;
		position: relative;
		margin: auto;
	}

	.cxCircles_tabs .ctb {
		width: 185px;
		height: 185px;
		border-radius: 50%;
		position: absolute;
		text-align: center;
		border: none;
		text-indent: 0;
		margin: 0;
		opacity: 1;
	}

	.cxCircles_tabs .ctb span {
		position: absolute;
		font-weight: 500;
		line-height: 1.2;
		width: 104px;
	}

	.cxCircles_tabs .tb1 {
		background: rgba(61, 138, 247, 0.4);
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	.cxCircles_tabs .tb1 span {
		left: 37px;
		top: 37px;
	}

	.cxCircles_tabs .tb2 {
		background: rgba(65, 198, 255, 0.3);
		left: 0;
		bottom: 0;
	}

	.cxCircles_tabs .tb2 span {
		margin: 25px 0;
		left: 10px;
		bottom: 20px;
	}

	.cxCircles_tabs .tb3 {
		background: rgba(135, 60, 236, 0.3);
		right: 0;
		bottom: 0;
	}

	.cxCircles_tabs .tb3 span {
		margin: 25px 0;
		right: 10px;
		bottom: 20px;
	}

	.cxCircles_tabs .tb1.active {
		background: rgba(61, 138, 247, 0.7)
	}

	.cxCircles_tabs .tb2.active {
		background: rgba(65, 198, 255, 0.7)
	}

	.cxCircles_tabs .tb3.active {
		background: rgba(135, 60, 236, 0.7)
	}

	.cxtabs {
		position: absolute;
		z-index: 3;
		top: 107px;
		left: 50%;
		transform: translateX(-50%);
	}

	#cxCirclessl .carousel-inner {
		margin-top: 20px;
	}

	#cxCirclessl .carousel-item h4 {
		font-size: 16px;
	}

	#cxCirclessl .carousel-item ul {
		margin-left: 20px;
	}

	.social-footer {
		padding-right: 15px;
	}

	.cta1 h3,
	.cta1 h4 {
		text-align: center;
	}

	#pageheader {
		padding: 25px 0;
		margin-top: 55px;
	}

	#pageheader h1,
	#pageheader .pageTitle {
		font-size: 30px;
	}

	#content h2 {
		font-size: 30px;
	}

	#content h3 {
		font-size: 22px;
	}

	#content h4 {
		font-size: 18px;
		margin-top: 15px;
	}

	.ctext,
	.cform-wrapper {
		padding: 0 30px;
	}

	.cinfo {
		font-size: 14px;
	}

	#problems .problem {
		padding: 10px 0 20px;
	}

	#problemSlider .owl-item {
		opacity: 0;
	}

	#problemSlider .owl-item.active {
		opacity: 1;
	}

	#pronvs {
		top: 10%;
		transform: none
	}

	#problems #problemSlider {
		padding-right: 32px;
	}

	#problems .problem img {
		width: 250px;
		margin-bottom: 15px;
	}

	.error404 h1 {
		font-size: 22px;
	}

	.error404 {
		padding: 0 20px;
	}

	.error404+#sitefooter {
		padding-bottom: 0;
	}

	.error-msg-wrapper p {
		font-size: 16px;
	}

	#blogDetail .blogTitle {
		font-size: 20px;
	}

	#bloglist li {
		padding: 0 10px 30px;
	}

	#bloglist li:last-child {
		padding-bottom: 0;
	}
}

@media screen and (min-width: 576px) {
	#cta_form .modal-dialog {
		max-width: 600px
	}

	#cta_form .modal-content {
		border-radius: 20px
	}

	#cta_form .modal-header {
		padding: 35px 35px 10px;
	}

	#cta_form .modal-body {
		padding: 15px 35px 35px;
	}
}

@media screen and (max-width:400px) {
	.cform {
		padding: 30px 20px;
	}
}

@media screen and (max-width:370px) {
	.contact-page h1 {
		font-size: 40px;
	}
}